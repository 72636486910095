import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

import SearchFilter from "../../components/parts/chart/search-filter"
import BarStacked from "../../components/parts/chart/bar-stacked"
import BarWaterfall from "../../components/parts/chart/bar-waterfall"
import ImageBox from "../../components/parts/image-box"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      s02_1_2_2: false,
      s02_1_2_4: false,
      s02_1_2_5: false,
      s02_1_2_6: false,
      s02_1_2_7: false
    }
    
    this.searchInit = {
      filter1Init: 1,
      filter2Init: 0,
      filter3Init: 'technology-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading}>{title}</h1>
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <BarStacked id="waterElectrolysis" chartData="waterElectrolysis" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              
            </div>
          </div>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>水素製造コスト</h2>
              </div>
              <div className={chartStyles.chartImageWrapper}>
                <button className={styles.modalLink} onClick={this.openModal} data-name={"s02_1_2_2"}>
                  <img src={'../../images/chart/hydrogen-production-cost.png'} alt="" width={559} />
                </button>
                <ImageBox
                  open={this.state.s02_1_2_2}
                  close={this.closeModal}
                  name={"s02_1_2_2"}
                  src={'/images/chart/hydrogen-production-cost.png'}
                  alt="水素製造コスト"
                  width={1200}
                  height={523} />
              </div>
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/the-future-of-hydrogen" target="_blank" rel="noreferrer noopener">IEA "The Future of Hydrogen"</a>（2019年6月）</p>
                <p className={chartStyles.chartExplain}>各国・地域別、製造方法別の水素製造コストを幅で表示。</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <BarWaterfall id="hydrogenSystemCost" chartData="hydrogenSystemCost" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>水電解水素製造効率</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s02_1_2_4"}>
                  <img src={'../../images/chart/water-electrolysis-plot-1.png'} alt="" width={559} />
                </button>
                <ImageBox
                  open={this.state.s02_1_2_4}
                  close={this.closeModal}
                  name={"s02_1_2_4"}
                  src={'/images/chart/water-electrolysis-plot-1.png'}
                  alt="水電解水素製造効率"
                  width={1200}
                  height={688} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.ipa.fraunhofer.de/de/Publikationen/studien/studie-indWEDe.html" target="_blank" rel="noreferrer noopener">NOW GmbH, "Industrialisierung der Wasserelektrolyse in Deutschland: Chancen und Herausforderungen für nachhaltigen Wasserstoff für Verkehr, Strom und Wärme"</a>（2018年）</p>
                <p className={chartStyles.chartExplain}>水電解方法別の水素生産量あたり電力消費量を、導入年次・規模別に表示。<br />
                <br />
                PEM（Proton exchange membrane electrolysis）：固体高分子膜水電解<br />
                SOEC（Solid oxide electrolysis cells）：固体酸化物型水電解</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>水電解スタック寿命</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s02_1_2_5"}>
                  <img src={'../../images/chart/water-electrolysis-plot-2.png'} alt="" width={559} />
                </button>
                <ImageBox
                  open={this.state.s02_1_2_5}
                  close={this.closeModal}
                  name={"s02_1_2_5"}
                  src={'/images/chart/water-electrolysis-plot-2.png'}
                  alt="水電解スタック寿命"
                  width={1200}
                  height={675} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.ipa.fraunhofer.de/de/Publikationen/studien/studie-indWEDe.html" target="_blank" rel="noreferrer noopener">NOW GmbH, "Industrialisierung der Wasserelektrolyse in Deutschland: Chancen und Herausforderungen für nachhaltigen Wasserstoff für Verkehr, Strom und Wärme"</a>（2018年）</p>
                <p className={chartStyles.chartExplain}>水電解方法別のスタック寿命を、導入年次・規模別に表示。<br />
                <br />
                PEM（Proton exchange membrane electrolysis）：固体高分子膜水電解</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>アンモニア製造コスト</h2>
              </div>
              <div className={chartStyles.chartImageWrapper}>
                <button className={styles.modalLink} onClick={this.openModal} data-name={"s02_1_2_6"}>
                  <img src={'../../images/chart/hydrogen-bar-line-1.svg'} alt="" width={559} />
                </button>
                <ImageBox
                  open={this.state.s02_1_2_6}
                  close={this.closeModal}
                  name={"s02_1_2_6"}
                  src={'/images/chart/hydrogen-bar-line-1.svg'}
                  alt="アンモニア製造コスト"
                  width={"100%"} />
              </div>
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/the-future-of-hydrogen" target="_blank" rel="noreferrer noopener">IEA, "The Future of Hydrogen"</a>（2019年6月）</p>
                <p className={chartStyles.chartExplain}>製造方法・原料別のアンモニア製造コストを表示。CCUSのコストには、CO2の捕集・運搬・貯蔵のコストを含む。<br />
                「電解」は100%再エネ電気由来、「バイオマス」は持続可能に生産されるものと仮定されている。<br />
                「total capture」は、エネルギー由来・プロセス由来のCO2をいずれも回収することを意味する。<br />
                「conc. capture（concentrated capture）」は、プロセス由来のCO2のみを回収することを意味する。<br />
                コストは地域により異なるため下限コストを記載し、地域差は「Range」として計上されている。<br />
                <br />
                NG：天然ガス</p>
              </div>

            </div>

            <div className={dashboardStyles.chartAreaItem}>
            <div className={chartStyles.chartTitle}>
                <h2>水素・アンモニア輸送コスト</h2>
              </div>
              <div className={chartStyles.chartImageWrapper}>
                <button className={styles.modalLink} onClick={this.openModal} data-name={"s02_1_2_7"}>
                  <img src={'../../images/chart/hydrogen-bar-line-2.png'} alt="" width={559} />
                </button>
                <ImageBox
                  open={this.state.s02_1_2_7}
                  close={this.closeModal}
                  name={"s02_1_2_7"}
                  src={'/images/chart/hydrogen-bar-line-2.png'}
                  alt="水素・アンモニア輸送コスト"
                  width={1200}
                  height={782} />
              </div>
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://ec.europa.eu/jrc/sites/default/files/jrc124206_assessment_of_hydrogen_delivery_options.pdf" target="_blank" rel="noreferrer noopener">JRC, "Assessment of Hydrogen Delivery Options"</a>（2021年6月）</p>
                <p className={chartStyles.chartExplain}>輸送方式別の水素、アンモニア輸送コストを表示。Hi、Loは水素生産地、水素消費地における電力価格の想定に応じた輸送コストの幅を示す。<br />
                Hi：水素生産地電力価格50€/MWh、消費地電力価格130€/MWh<br />
                Lo：水素生産地電力価格10€/MWh、消費地電力価格50€/MWh<br />
                <br />
                H2 Pipeline：水素パイプライン<br />
                Compressed H2：圧縮水素<br />
                Liquifed H2：液体水素<br />
                LOHC（Liquid Organic Hydrogen Carrier）：液体有機水素キャリア<br />
                Ammonia：アンモニア</p>
              </div>

            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>国際競争力 水電解装置</h2>
              </div>
              <img src="/images/chart/bubble-hydrogenCompetitiveness-waterElectrolyzer.svg" alt="" width="100%" />
              <div className={chartStyles.chartNote}>
                {/*<p className={chartStyles.chartSource}>出所）<a href="https://www.nrel.gov/pv/module-efficiency.html" target="_blank" rel="noreferrer noopener">NEDO, 「日系企業のIT サービス、ソフトウェア及びモノの国際競争ポジションに関する情報収集」</a>（2021年3月）を基に作成</p>*/}
                <p className={chartStyles.chartSource}>出所）NEDO, 「日系企業のIT サービス、ソフトウェア及びモノの国際競争ポジションに関する情報収集」（2021年3月）を基に作成</p>
                <p className={chartStyles.chartExplain}>横軸：各国企業の世界シェア、縦軸：世界市場規模、バブルサイズ：各国企業の販売金額<br />
                国の分類は企業国籍に基づき行い、複数国の資本からなる合弁会社については出資比率が50%を越す企業の国籍/地域に準じている。</p>
                <p><a className={chartStyles.chartDataDownload} href="/csv/bubble-chart/hydrogenCompetitiveness-waterElectrolyzer.csv">データダウンロード</a></p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`