import React from "react"
import { StaticQuery, graphql } from "gatsby"
import * as am5 from "@amcharts/amcharts5/"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import * as styles from "../../../styles/components/chart.module.scss"
import _ from "lodash"
import canvasMemoryReset from "../../../utils/canvas-memory-reset"

// markup
class BarWaterfall extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData
    }

    this.filePath = "/csv/bar-waterfall/" // CSVデータ格納先
  }

  // config setter
  setConfig(data) {
    this.config = data
  }

  // config getter
  getConfig() {
    return this.config
  }

  // チャートの設定
  async setChartData() {
    am5.addLicense(process.env.AM_CHARTS_LICENSE)
    
    let chartConfig = this.getConfig()
    let root = am5.Root.new(this.props.id)
    let xLabel = chartConfig.axis[this.state.chartData][0].title
    let chartUpper = chartConfig.chart[this.state.chartData].waterfall.upper
    let chartLower = chartConfig.chart[this.state.chartData].waterfall.lower

    root.setThemes([
      am5themes_Animated.new(root)
    ])
    this.root = root

    // 基本設定
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      layout: root.verticalLayout
    }));
    chart.children.unshift(am5.Label.new(root, {
      text: chartConfig.title[this.state.chartData],
      fontSize: "12px",
      centerX: am5.percent(50),
      x: am5.percent(50)
    }))

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      fontSize: "11px"
    })

    // X座標設定
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      categoryField: xLabel,
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }))
    this.xAxis = xAxis

    // Y座標設定
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0,
      min: chartConfig.axis[this.state.chartData][1].min,
      max: chartConfig.axis[this.state.chartData][1].max,
      renderer: am5xy.AxisRendererY.new(root, {}),
      tooltip: am5.Tooltip.new(root, {})
    }))
    let rangeDataItem = yAxis.makeDataItem({
      value: chartConfig.axis[this.state.chartData][1].range
    })
    yAxis.createAxisRange(rangeDataItem)
    
    let yRenderer = yAxis.get('renderer')
    yRenderer.labels.template.setAll({
      fontSize: "11px"
    })

    yAxis.children.unshift(am5.Label.new(root, {
      rotation: -90,
      text: chartConfig.axis[this.state.chartData][1].title,
      y: am5.p50,
      centerX: am5.p50,
      fontSize: "11px",
    }))
    this.yAxis = yAxis

    // シリーズの指定
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      openValueYField: "open",
      categoryXField: "category"
    }));
    series.columns.template.setAll({
      tooltipText: `{categoryX}\n{valueY} - {openValueY}`,
      templateField: "columnConfig",
      strokeOpacity: 0
    })

    // CSVファイルからデータの読み込み
    let fields = chartConfig.chart[this.state.chartData].convert.numericFields
    let dataSource = `${this.filePath}${this.state.chartData}.csv`
    let data = await am5.net.load(dataSource).then(function(result) {
      // CSVパースオプション
      let newData = []
      let data = am5.CSVParser.parse(result.response, {
        delimiter: ",",
        reverse: false,
        skipEmpty: true,
        useColumnNames: true
      });
      // 型変換処理
      
      let processor = am5.DataProcessor.new(root, {
        numericFields: fields
      });
      processor.processMany(data);
      // グルーピング用にCSVデータを加工
      data.forEach((obj, id) => {
        newData[id] = {}
        for (let k in obj) {
          if (k === xLabel) {
            newData[id][k] = `${obj['provider']} ${obj[xLabel]}`
            newData[id]['realName'] = obj[xLabel]
            if ('現状' === obj[xLabel]) {
              newData[id]['columnConfig'] = {
                fill: "#ED7D31",
                tooltipText: `[#FFFFFF]{categoryX}[/]\n[#FFFFFF]{valueY} - {openValueY}[/]`
              }
            } else {
              newData[id]['columnConfig'] = {
                tooltipText: `[#FFFFFF]{categoryX}[/]\n[#FFFFFF] < {openValueY}[/]`,
                fillGradient: am5.LinearGradient.new(root, {
                  rotation: 90,
                  stops: [{color:am5.color(0x95abea)}, {color: am5.color(0xffffff)}]
                }
              )  
            }
          }
          } else {
            if (k === chartUpper) {
              newData[id]['open']  = parseInt(obj[chartUpper])
              newData[id]['value'] = parseInt(obj[chartLower])
            } else if (k === chartLower) {
              newData[id]['stepValue'] = parseInt(obj[chartUpper]) - parseInt(obj[chartLower])
            } else {
              newData[id][k] = obj[k]
            }
          }
        }
      })
      return newData
    })

    // X座標のラベルを指定
    xRenderer.labels.template.setAll({
      text: "{realName}",
      fontSize: "11px"
    });

    // Providerでグルーピング
    let byName = _.groupBy(data, 'provider')
    this.createProvider(byName)

    xAxis.data.setAll(data)
    series.data.setAll(data)
    series.appear();

    this.chart = chart
  }

  // Providerの指定
  createProvider(data) {
    let xAxis = this.xAxis
    Object.keys(data).forEach((key) => {
      let range = xAxis.makeDataItem({})
      xAxis.createAxisRange(range)
      range.set("category", data[key][0].category);
      range.set("endCategory", data[key][data[key].length - 1].category)
      
      let label = range.get("label")
      label.setAll({
        text: data[key][0].provider,
        dy: 20
      })
      
      let tick = range.get("tick");
      tick.setAll({ visible: true, strokeOpacity: 0.3, length: 40, location: 0 });
    
      //let grid = range.get("grid");
      //grid.setAll({ strokeOpacity: 1 });
    })
  }

  componentDidMount() {
    this.setChartData()
  }

  componentWillUnmount() {
    canvasMemoryReset(`#${this.props.id} canvas`)
    if (this.root) {
      this.root.dispose()
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query allBarWaterfallQuery {
            settings: allBarWaterfallJson {
              edges {
                node {
                  axis {
                    hydrogenSystemCost {
                      label
                      max
                      min
                      range
                      title
                      type
                    }
                  }
                  chart {
                    hydrogenSystemCost {
                      convert {
                        numericFields
                      }
                      waterfall {
                        lower
                        upper
                      }
                    }
                  }
                  title {
                    hydrogenSystemCost
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            { this.setConfig(data.settings.edges[0].node) }
            <div className={styles.chartTitle}>
              <h2>水電解システムコスト</h2>
            </div>
            <div id={this.props.id} style={{ width: this.props.width, height: this.props.height }}></div>
            <div className={styles.chartNote}>
              <p className={styles.chartSource}>出所）<a href="https://www.irena.org/publications/2020/Dec/Green-hydrogen-cost-reduction" target="_blank" rel="noreferrer noopener">IRENA, "Green Hydrogen Cost Reduction"</a>（2020年12月）を基に作成</p>
              <p className={styles.chartExplain}>水電解方法別のシステムコストを幅で表示<br />
              <br />
              PEM（Proton exchange membrane electrolysis）：固体高分子膜水電解<br />
              AEM（Anion  exchange membrane electrolysis）：アニオン交換膜水電解<br />
              SOEC（Solid oxide electrolysis cells）：固体酸化物型水電解</p>
              <p><a className={styles.chartDataDownload} href={`${this.filePath}${this.state.chartData}.csv`}>データダウンロード</a></p>
            </div>
          </>
        )}
      />
    )
  }
}
export default BarWaterfall
